
/* SLIDER */
.slider {
  // max-width: 100vw;
  height: 35rem;
  height: 50vh;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 35rem;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-color: black;
  /* THIS creates the animation! */
  transition: transform 2s;
}

.slide > img {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  background: rgba(255, 255, 255, 0.7);
  font-family: inherit;
  color: #333;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  font-size: 2.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dots__dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  //background-color: #fff;
  background-color: #888;
  background-color: white;
  opacity: 1;
}

/* TESTIMONIALS */
.testimonial {
  width: 80%;
  position: relative;
}

// .testimonial::before {
//   content: '\201C';
//   position: absolute;
//   top: -5.7rem;
//   left: -6.8rem;
//   line-height: 1;
//   font-size: 13rem;
//   font-family: inherit;
//   color: var(--color-primary);
//   z-index: -1;
// }

.testimonial__header {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #fff;//if dark backround
}

.testimonial__text {
  font-size: 1.2rem;
  margin-bottom: 3.5rem;
  color: #666;
  color: #fff;//if dark backround
}

.testimonial__author {
  margin-left: 0.4rem;
  font-style: normal;

  display: grid;
  grid-template-columns: 6.5rem 1fr;
  column-gap: 2rem;
}

.testimonial__photo {
  grid-row: 1 / span 2;
  width: 6.5rem;
  border-radius: 50%;
}

.testimonial__name {
  font-size: 1.2rem;
  font-weight: 500;
  align-self: end;
  margin: 0;
  color: #fff;//if dark backround
}

.testimonial__location {
  font-size: 1rem;
  color: #fff;//if dark backround
}

.section__title--testimonials {
  margin-bottom: 4rem;
}


// Mobile Styles
@media only screen and (max-width: 600px) {
/* SLIDER */
.slider {
  height: 45rem;
}

.slide {
  height: 45rem;
}
}
// Mobile Styles
@media only screen and (max-width: 350px) {
  /* SLIDER */
  .slider {
    height: 60rem;
  }
  
  .slide {
    height: 60rem;
  }
  }
  // Mobile Styles
@media only screen and (max-height: 650px) {
  /* SLIDER */
  .slider {
    height: 35rem;
  }
  
  .slide {
    height: 35rem;
  }
  }