@import '../../variables.module.scss';

// Select an ImageHover effect by setting the right class to the Blog.js -> Within the Card Element i.e::after
// styles.hover01
// styles.hover02
// styles.hover03
// styles.hover04
// styles.hover05
// for more effects: https://codepen.io/nxworld/pen/ZYNOBZ

.column div {
	position: relative;
	// float: none;
	// width: 300px;
	height: 300px;
	// margin: 0 0 0 5px;
	padding: 0;
}

.figure {
	// width: 300px;
	height: 300px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}
.figure:hover+span {
	bottom: -36px;
	opacity: 1;
}

/* Zoom In #1 */
.hover01 figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover01 figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

/* Zoom In #2 */
.hover02 figure img {
	width: 100%;
	height: auto;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover02 figure:hover img {
	width: 150%;
}

/* Zoom Out #1 */
.hover03 figure img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transition: 1.3s ease-in-out;
	transition: 1.3s ease-in-out;
}
.hover03 figure:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

/* Zoom Out #2 */
.hover04 figure img {
	width: 400px;
	height: auto;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover04 figure:hover img {
	width: 300px;
}

/* Slide */
.hover05 figure img {
	margin-left: 30px;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover05 figure:hover img {
	margin-left: 0;
}

// PostTwo
.post-date{
	color: #fff;
	position: absolute;
	right: 20px;
	top: 20px;
	text-align: center;
	text-transform: uppercase;
	background-color: #F5BF23;
	z-index: 1;
	padding: 15px;
} 
.post-date-strong{
	display: block;
}

.gotoblog-detail-button{
	
	background-color: $initial_button_primary_color !important;
  border-color: $initial_button_primary_color !important;
}


.categories-quick-filter-button{
	margin:5px !important;
	background-color: $initial_button_primary_color !important;
  border-color: $initial_button_primary_color !important;
}

.blog-sorting-button
{
	margin:5px !important;
	color: $initial_button_primary_color !important;
	background-color: white !important;
  border-color: $initial_button_primary_color !important;
}
.pagination-item{
	span{
		background-color: $initial_button_primary_color !important;
		border-color: $initial_button_primary_color !important;
	}
	a{
		color: $initial_button_primary_color !important;
	}
}